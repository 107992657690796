import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import GridCountdown from './GridCountdown';
import ProductOptionsDialog from './ProductOptionsDialog';

const Product = (props) => {
  const { product } = props;

  const [style, setStyle] = useState({
    display: 'none'
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const image1 = product.variants
    ? product.variants[0].image
    : product.images[0];
  /* const image2 = product.images[1]; */

  const imageSrc = image1 ? image1.transformedSrc : '';

  /*  const handleImgHover = () =>
    setImageSrc((prev) => (image2 ? image2.transformedSrc : prev));
  const handleImgOut = () => setImageSrc(image1 ? image1.transformedSrc : ''); */

  return (
    <>
      <div className="Product" key={product.id}>
        <div
          className="Product__ImageContainer"
          onMouseEnter={() => {
            setStyle({
              display: 'block',
              position: 'absolute',
              bottom: '0px',
              borderRadius: '0px'
            });
            /*  handleImgHover(); */
          }}
          onMouseLeave={() => {
            setStyle({ display: 'none' });
            /*  handleImgOut(); */
          }}
          onClick={handleDialogOpen}
        >
          {image1 ? (
            <img
              className="Product__Image"
              src={imageSrc}
              alt={`${product.title} product shot`}
            />
          ) : (
            <img
              className="Product__Image"
              src="placeholder-image.png"
              alt={`${product.title} product shot`}
            />
          )}
          {product.endDate && <GridCountdown date={product.endDate} />}
          <StyledButton
            className="Product__OpenDetailsButton"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            style={style}
            onClick={(e) => {
              e.stopPropagation();
              handleDialogOpen();
            }}
          >
            SELECT OPTIONS
          </StyledButton>
        </div>
        <div className="Product__info">
          <h4 className="Product__title">{product.title}</h4>
          <p className="Product__price">${product.variants[0].price.amount}</p>
        </div>
      </div>
      <ProductOptionsDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        product={product}
      />
    </>
  );
};

Product.propTypes = {
  product: PropTypes.object
};

export default Product;

const StyledButton = styled(Button)`
  @media (max-width: 400px) {
    padding: 3px !important;
  }

  .MuiButton-label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: white;

    @media (min-width: 400px) {
      font-size: 15px;
    }

    @media (min-width: 960px) {
      font-size: 16px;
    }

    @media (min-width: 1500px) {
      font-size: 20px;
    }
  }
`;
